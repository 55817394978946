<template>
  <div>
    <div id="tnc-page" class="flex-column">
      <div class="flex-center width-1000 body-2">
        <div>
          <h1>Terms and Conditions</h1>
        </div>

        <div>
          <h3>Welcome to iAppraise</h3>
          <p>
            These terms and conditions outline the rules and regulations for the use of iAppraise's Website. iAppraise
            is located at:
            <a href="https://www.iappraise.net.au/terms-and-conditions" target="_blank"
              >https://www.iappraise.net.au/terms-and-conditions</a
            >
            .
          </p>
          <p>
            By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
            iAppraise's website if you do not accept all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and
            any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and
            accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to
            our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or
            ourselves.
          </p>
          <p>
            All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of
            our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration,
            or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the
            Company’s stated services/products, in accordance with and subject to, prevailing law of .
          </p>
          <p>
            Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or
            they, are taken as interchangeable and therefore as referring to same.
          </p>
          <p></p>

          <h3>Cookies</h3>
          <p>
            We employ the use of cookies. By using I Appraise's website you consent to the use of cookies in accordance
            with iAppraise’s privacy policy.Most of the modern day interactive web sites use cookies to enable us to
            retrieve user details for each visit.
          </p>
          <p>
            Cookies are used in some areas of our site to enable the functionality of this area and ease of use for
            those people visiting. Some of our affiliate / advertising partners may also use cookies.
          </p>

          <h3>License</h3>
          <p>
            Unless otherwise stated, iAppraise and/or it’s licensors own the intellectual property rights for all
            material on iAppraise. All intellectual property rights are reserved. You may view and/or print pages from
            <a href="http://www.iappraise.net.au" target="_blank">http://www.iappraise.net.au</a>
            for your own personal use subject to restrictions set in these terms and conditions.
          </p>
          <p>You must not:</p>
          <ul>
            <li>
              Republish material from
              <a href="http://www.iappraise.net.au" target="_blank">http://www.iappraise.net.au</a>
            </li>
            <li>
              Sell, rent or sub-license material from
              <a href="http://www.iappraise.net.au" target="_blank">http://www.iappraise.net.au</a>
            </li>
            <li>
              Reproduce, duplicate or copy material from
              <a href="http://www.iappraise.net.au" target="_blank">http://www.iappraise.net.au</a>
            </li>
            <li>Redistribute content from iAppraise (unless content is specifically made for redistribution).</li>
            <li>Hyperlinking to our Content</li>
          </ul>
          <p>The following organizations may link to our Web site without prior written approval:</p>
          <ul>
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organizations</li>
            <li>
              Online directory distributors when they list us in the directory may link to our Web site in the same
              manner as they hyperlink to the Web sites of other listed businesses
            </li>
            <li>
              Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and
              charity fundraising groups which may not hyperlink to our Web site
            </li>
            <li>
              These organizations may link to our home page, to publications or to other Web site information so long as
              the link:
              <ol style="list-style-type: lower-alpha">
                <li>Is not in any way misleading.</li>
                <li>
                  Does not falsely imply sponsorship, endorsement orapproval of the linking party and its products or
                  services.
                </li>
                <li>Fits within the context of the linking party's site.</li>
              </ol>
            </li>
          </ul>
          <p>
            We may consider and approve in our sole discretion other link requests from the following types of
            organizations:
          </p>
          <ul>
            <li>
              Commonly-known consumer and/or business information sources such as Chambers of Commerce, American
              Automobile Association, AARP and Consumers Union
            </li>
            <li>Dot.com community sites</li>
            <li>
              Associations or other groups representing charities, including charity giving sites, online directory
              distributors, internet portals accounting, law and consulting firms whose primary clients are businesses
            </li>
            <li>Educational institutions and trade associations</li>
          </ul>
          <p>We will approve link requests from these organizations if we determine that:</p>
          <ol style="list-style-type: lower-alpha">
            <li>
              The link would not reflect unfavorably on us or our accredited businesses (for example, trade associations
              or other organizations representing inherently suspect types of business, such as work-at-home
              opportunities, shall not be allowed to link)
            </li>
            <li>The organization does not have an unsatisfactory record with us</li>
            <li>The benefit to us from the visibility associated with the hyperlink outweighs the absence of ;</li>
            <li>
              Where the link is in the context of general resource information or is otherwise consistent with editorial
              content in a newsletter or similar product furthering the mission of the organization.
            </li>
          </ol>
          <p>
            These organizations may link to our home page, to publications or to other Web site information so long as
            the link:
          </p>
          <ol style="list-style-type: lower-alpha">
            <li>Is not in any way misleading</li>
            <li>
              Does not falsely imply sponsorship, endorsement or approval of the linking party and it products or
              services
            </li>
            <li>Fits within the context of the linking party's site.</li>
          </ol>
          <p>
            If you are among the organizations listed in paragraph 2 above and are interested in linking to our website,
            you must notify us by sending an e-mail to
            <a href="mailto:admin@iappraise.com.au">admin@iappraise.com.au</a>.
          </p>
          <p>
            Please include your name, your organization name, contact information (such as a phone number and/or e-mail
            address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site,
            and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.
          </p>
          <p>Approved organizations may hyperlink to our Web site as follows:</p>
          <ul>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator (Web address) being linked to; or</li>
            <li>
              By use of any other description of our Web site or material being linked to that makes sense within the
              context and format of content on the linking party's site.
            </li>
            <li>
              No use of IAppraise’s logo or other artwork will be allowed for linking absent a trademark license
              agreement.
            </li>
          </ul>
          <h4>Iframes</h4>
          <p>
            Without prior approval and express written permission, you may not create frames around our Web pages or use
            other techniques that alter in any way the visual presentation or appearance of our Web site.
          </p>
          <h4>Reservation of Rights</h4>
          <p>
            We reserve the right at any time and in its sole discretion to request that you remove all links or any
            particular link to our Web site. You agree to immediately remove all links to our Web site upon such
            request. We also reserve the right to amend these terms and conditions and its linking policy at any time.
            By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and
            conditions.
          </p>
          <h4>Removal of links from our website</h4>
          <p>
            If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us
            about this. We will consider requests to remove links but will have no obligation to do so or to respond
            directly to you. Whilst we endeavour to ensure that the information on this website is correct, we do not
            warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or
            that the material on the website is kept up to date.
          </p>
          <h4>Content Liability</h4>
          <p>
            We shall have no responsibility or liability for any content appearing on your Web site. You agree to
            indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear
            on any page on your Web site or within any context containing content or materials that may be interpreted
            as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
            other violation of, any third party rights.
          </p>
          <h4>Disclaimer</h4>
          <p>
            To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
            relating to our website and the use of this website (including, without limitation, any warranties implied
            by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
            Nothing in this disclaimer will: limit or exclude our or your liability for death or personal injury
            resulting from negligence; limit or exclude our or your liability for fraud or fraudulent misrepresentation;
            limit any of our or your liabilities in any way that is not permitted under applicable law; or exclude any
            of our or your liabilities that may not be excluded under applicable law.
          </p>
          <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer:</p>
          <ol style="list-style-type: lower-alpha">
            <li>Are subject to the preceding paragraph; and</li>
            <li>
              Govern all liabilities arising under the disclaimer or in relation to the subject matter of this
              disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of
              statutory duty.
            </li>
          </ol>
          <p>
            To the extent that the website and the information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions"
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
@import "@/scss/v2/legal.scss";

#tnc-page {
  padding-top: $PADDING_X2_05;
}
</style>
